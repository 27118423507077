<template><!-- eslint-disable max-len -->
  <section class="Page UploadYourDocuments">
    <h2 class="Title Title--type-h2 Page-Title PaymentOptions-Title UploadYourDocuments-Title-2">
      <span class="Colored">{{ $t('uploadDocs.title') }}</span>
    </h2>
    <div class="Page-Section UploadYourDocumentsPage-Section">
      <h3 class="Title Title--type-h3 Page-Title UploadYourDocumentsPage-Title-3">
        {{ $t('uploadDocs.contentTop.title') }}
      </h3>
      <p v-for="(text, i) in $t('uploadDocs.contentTop.infoTop')" :key="`text${i}`">
        {{ $t(text.text) }}
      </p>
      <div class="UploadYourDocumentsPage-Content">
        <div class="UploadYourDocumentsPage-Info">
          <h4 class="Title Title--type-h4 Page-Title UploadYourDocumentsPage-Title-4">
            {{ $t('uploadDocs.proofId.title') }}
          </h4>
          <p>{{ $t('uploadDocs.proofId.textInclude') }}</p>
          <strong v-for="(text, i) in $t('uploadDocs.proofId.strong')" :key="`text${i}`">
            {{ $t(text.text) }}
          </strong>
          <p>{{ $t('uploadDocs.proofId.textMust') }}</p>
          <ul>
            <li v-for="(item, i) in  $t('uploadDocs.proofId.proofList') " :key="`item${i}`">
              {{ $t(item.text) }}
            </li>
          </ul>
        </div>
        <div class="UploadYourDocumentsPage-Photo">
          <img src="@/assets/img/doc-eu_id.png" alt="eu_id">
        </div>
      </div>
      <div class="UploadYourDocumentsPage-Content">
        <div class="UploadYourDocumentsPage-Info">
          <h4 class="Title Title--type-h4 Page-Title UploadYourDocumentsPage-Title-4">
            {{ $t('uploadDocs.proofAddress.title') }}
          </h4>
          <p>{{ $t('uploadDocs.proofAddress.textInclude') }}</p>
          <strong v-for="(text, i) in $t('uploadDocs.proofAddress.strong')" :key="`text${i}`">
            {{ $t(text.text) }}
          </strong>
          <p>{{ $t('uploadDocs.proofAddress.textMust') }}</p>
          <ul>
            <li v-for="(item, i) in  $t('uploadDocs.proofAddress.proofList') " :key="`item${i}`">
              {{ $t(item.text) }}
            </li>
          </ul>
        </div>
        <div class="UploadYourDocumentsPage-Photo">
          <img src="@/assets/img/doc-eu_address.png" alt="eu_address">
        </div>
      </div>
      <div class="UploadYourDocumentsPage-Content">
        <div class="UploadYourDocumentsPage-Info">
          <h4 class="Title Title--type-h4 Page-Title UploadYourDocumentsPage-Title-4">
            {{ $t('uploadDocs.creditCard.title') }}
          </h4>
          <p>{{ $t('uploadDocs.creditCard.textCreditChoose') }}</p>
          <p>{{ $t('uploadDocs.creditCard.textFollowing') }}</p>
          <strong v-for="(text, i) in $t('uploadDocs.creditCard.strong')" :key="`text${i}`">
            {{ $t(text.text) }}
          </strong>
          <ul>
            <li v-for="(text, i) in $t('uploadDocs.creditCard.proofList')" :key="`text${i}`">
              {{ $t(text.text) }}
            </li>
            <li>Only the first 4 digits and last 4 digits of the card must be visible</li>
            <li>Cover the 8 middle digits of the card number.</li>
          </ul>
        </div>
        <div class="UploadYourDocumentsPage-Photo">
          <img src="@/assets/img/doc-credit_valid.png" alt="credit_valid">
        </div>
      </div>
      <div class="UploadYourDocumentsPage-Bottom">
        <p v-for="(text, i) in $t('uploadDocs.contentBottom')" :key="`text${i}`">
          {{ $t(text.text) }}
        </p>
        <button
          class="Btn Btn--outline Btn--outline1 MainNav-Btn UploadYourDocumentsPage-Btn"
          @click="openRegistration()"
        >
          {{ $t('menu.login') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/mixins/auth';

export default {
  name: 'UploadYourDocuments',
  mixins: [auth],
};
</script>

<style lang="scss" scoped>
.UploadYourDocuments-Title-2 {
  font-size: 20px;
  margin-bottom: 25px;
  @media(min-width: $screen-l) {
    margin-bottom: 45px;
    font-size: 45px;
  }
}

.UploadYourDocumentsPage-Title-3 {
  font-size: 18px;
  font-weight: 700;
  @media(min-width: $screen-l) {
    font-size: 24px;
  }
}

.UploadYourDocumentsPage-Title-4 {
  font-size: 16px;
  font-weight: 700;
  @media(min-width: $screen-l) {
    font-size: 18px;
    margin: 0 0 20px;
  }
}

.UploadYourDocumentsPage-Content {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-text-ghost);
  padding: 10px 0 10px;

  strong {
    display: block;
  }

  ul {
    list-style-type: disc;
    margin: 0;
    padding: 0 0 0 18px;
  }

  @media(min-width: $screen-m) {
    display: flex;
    justify-content: space-between;

    margin-bottom: 35px;
    border-bottom: 1px solid var(--color-text-ghost);
    padding: 20px 0 20px;
  }
}

.UploadYourDocumentsPage-Info {
  margin-bottom: 25px;
  @media(min-width: $screen-m) {
    max-width: 200px;
    width: 100%;
  }
  @media(min-width: $screen-l) {
    max-width: 300px;
  }
}

.UploadYourDocumentsPage-Photo {
  max-width: 300px;
  width: 100%;
  @media(min-width: $screen-l) {
    max-width: 500px;
  }
}

.UploadYourDocumentsPage-Bottom {
  padding: 20px 0 0;
  text-align: center;
  @media(min-width: $screen-xl) {
    padding: 20px 0 0;
    text-align: center;
  }
}

.UploadYourDocumentsPage-Btn {
  padding: 9px 45px;
}
</style>
